.faq-section {
  background-color: #fff;
  padding: 2rem;
  max-width: 1000px; /* Adjusted for wider layout */
  margin: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds shadow to the whole section */
  margin-top: 20px;
  border-radius: 10px;
}

.faq-section h2 {
  text-align: left; /* Align to the left */
  margin-bottom: 2rem;
  font-size: 1.5rem; /* Larger font size for the heading */
}

.faq-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  column-gap: 3rem; /* More space between columns */
}

.faq-item {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Add transform to transition for a pop effect */
  overflow: hidden; /* Added for animation */
}

.faq-item:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-3px); /* Slight raise effect on hover */
}

.faq-item.active {
  background-color: #e6e6e6; /* Lighter background for active item */
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 1rem; /* Adjust font size if necessary */
  padding: 1rem; /* Uniform padding */
}

.faq-question:hover {
  background-color: #f2f2f2; /* Slight background change on hover */
}

.faq-answer {
  max-height: 0; /* Start with max-height 0 for animation */
  overflow: hidden; /* Hide the overflow content */
  transition: max-height 0.5s ease; /* Animate max-height property */
  padding: 0 1rem; /* Padding for answer text, moved from .show */
  color: #333;
  line-height: 1.5;
}

.faq-answer.show {
  max-height: 200px; /* Max height for visible answer, adjust as needed */
}

.faq-toggle {
  font-size: 1.5rem; /* Larger toggle size */
  font-weight: bold; /* Make it bold */
  transition: transform 0.3s ease; /* Animate the rotation */
}

.faq-item.active .faq-toggle {
  transform: rotate(180deg); /* Rotate the toggle when active */
}

/* Responsive layout adjustments */
@media (max-width: 768px) {
  .faq-container {
    grid-template-columns: 1fr;
  }

  .faq-section h2 {
    text-align: center; /* Center align the heading on smaller screens */
    margin-bottom: 1.5rem;
  }
}
