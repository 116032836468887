.feature-section {
    text-align: center;
    padding: 50px 20px;
    background: #F8F8F8; /* Change to match your design */
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .feature-section h2 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  .feature-section p {
    font-size: 18px;
    color: #666666;
    margin-bottom: 40px;
  }
  
  .feature-cards {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .feature-card {
    width: 33%; /* Adjust the width as necessary */
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* This adds a subtle shadow around the card */
    border-radius: 10px; /* Rounded corners for the card */
  }
  
  .feature-card img {
    width: 70px; /* Adjust based on your icon size */
    height: auto;
  }
  
  .feature-card h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  .feature-card p {
    font-size: 16px;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .feature-cards {
      flex-direction: column;
    }
  
    .feature-card {
      width: 100%; /* Full width on smaller screens */
      margin-bottom: 20px;
    }
  }

  
  