.main-section {
  padding: 15px 10px;
  font-family: Arial, Helvetica, sans-serif;
  background: #F8F8F8; /* This is a placeholder, match to your design */
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #F8F8F8; /* Or any other grey color you prefer */
}

#root, #app {
  min-height: 100vh; /* This will cover the full viewport height */
  display: flex;
  flex-direction: column;
}