.hero-section {
    text-align: center;
    padding: 50px 20px;
    font-family: Arial, Helvetica, sans-serif;
    background: #F8F8F8; /* This is a placeholder, match to your design */
  }
  
  .release-info-container {
    display: inline-flex; /* Align the release tag and the link side by side */
    align-items: center; /* Center them vertically */
    background-color: #f4f4f4; /* Set the background color to white */
    border-radius: 20px; /* Rounded corners for the container */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); Shadow for depth */
    padding: 5px 15px; /* Padding inside the container */
    margin-bottom: 20px; /* Space below the container */
    gap: 10px; /* Space between the elements inside the container */
  }
  
  .release-tag {
    font-size: 14px;
    color: #888888;
    margin: 0; /* Remove margin if any */
  }
  
  .update-link {
    font-size: 14px;
    color: #3F51B5;; /* Orange color for the link */
    padding: 5px 10px; /* Padding to make it look like a button */
    border-radius: 10px; /* Rounded corners for the link/button */
    text-decoration: none; /* Remove the underline */
    background-color: #f2f2f2; /* Slightly different background for the link/button */
    transition: background-color 0.2s ease; /* Smooth transition for hover effect */
  }
  
  .update-link:hover {
    background-color: #e9e9e9; /* Change background on hover */
  }
  
  h1 {
    font-size: 68px;
    font-weight: bold;
    margin: 20px 0;
    color: #333333; /* This is a placeholder, match to your design */
  }
  
  h1 .highlight {
    background: linear-gradient(45deg, #FFA500, #FF4500); /* Adjust the gradient colors to match the design */
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent; /* Make the text color transparent to show the gradient */
    display: inline; /* This ensures the gradient applies only to the text */
  }
  
  p {
    font-size: 18px;
    color: #666666; /* This is a placeholder, match to your design */
    margin-bottom: 30px;
  }
  
  .download-button {
    padding: 15px 30px;
    font-size: 16px;
    background: #FFFFFF; /* This is a placeholder, match to your design */
    border: none;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .download-button:hover {
    background: #F0F0F0; /* Slightly darker on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  /* Responsive styles if necessary */
  @media (max-width: 768px) {
    h1 {
      font-size: 32px;
    }
  
    p {
      font-size: 16px;
    }
  }

/* Large screen adjustments */
@media (min-width: 992px) { /* Adjust '992px' to the breakpoint you need */
  .hero-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 20px; /* Remove top/bottom padding for larger screens */
      min-height: 80vh; /* Minimum height to cover most of the viewport */
      height: auto; /* Height auto for the content to dictate the total height */
  }

  /* Adjustments to h1 and other elements for larger screens */
  h1 {
      margin-top: 0;
      margin-bottom: 20px;
  }

  /* Adjust the paragraph and button as needed for large screens */
}


  