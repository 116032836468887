.navigation-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 10px 20px;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 10px;
  }

.highlight {
    color: #3F51B5; /* This is a placeholder, match to your design */
  }
  
  .nav-brand {
    font-size: 24px;
    font-weight: bold;
    transition: transform 0.3s ease; /* Animation for the brand */
  }
  
  .nav-brand:hover {
    transform: scale(1.05); /* Slightly increase size */
  }
  
  .nav-items a {
    text-decoration: none;
    margin-left: 20px;
    color: #828282; /* Change this to match your color scheme */
    font-size: 16px;
    font-weight: 500;
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition for color and transform */
  }
  
  .nav-items a:hover {
    color: #000000; /* Darken the color a bit on hover */
    transform: translateY(-3px); /* Move up slightly when hovered */
  }
  
  /* Add responsive or additional styles as needed */
  .menu-toggle {
    display: none; /* Hide the toggle button by default */
    font-size: 24px; /* Adjust size as needed */
    background: none;
    border: none;
    cursor: pointer;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .nav-items {
      display: none; /* Hide the nav items initially */
      position: absolute; /* Position them absolutely to overlay content */
      background-color: #ffffff; /* Match the navbar color */
      top: 60px; /* Position below the navbar */
      left: 10px;
      right: 10px;
      flex-direction: column;
      align-items: flex-start;
      padding: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Adds a shadow for better visibility */
    }
  
    .nav-items.open {
      display: flex; /* Show the nav items when the menu is open */
    }
  
    .nav-items a {
      margin: 10px 20px; /* Adjust spacing for the nav items */
      width: calc(100% - 40px); /* Full width minus padding */
    }

    .menu-toggle {
      display: block; /* Show the toggle button on small screens */
    }
  }