.privacy-page {
    text-align: center;
    padding: 50px 20px;
    background: #F8F8F8;
  }
  
  .privacy-page h2 {
    margin-bottom: 1rem;
    font-size: 2rem;
    color: #3F51B5;;
  }
  
  .privacy-page p {
    margin-bottom: 2rem;
    color: #666;
  }
  
  .privacy-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
  }
  
  .privacy-card {
    background: #ffffff;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
  }
  
  .privacy-card:hover {
    transform: translateY(-5px);
  }
  
  .privacy-icon {
    width: 50px; /* Adjust size as needed */
    height: auto;
    margin-bottom: 1rem;
  }
  
  .privacy-card h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  .privacy-card p {
    font-size: 1rem;
    color: #666;
  }
  